<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
  <div class="flex flex-column align-items-center justify-content-center">
    <!--    <img src="assets/layout/images/{{layoutConfig().colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg" alt="Sakai logo" class="mb-5 w-6rem flex-shrink-0">-->
    <div style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">
      <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
        <div class="text-center mb-5">
          <!--          <img src="assets/demo/images/login/avatar.png" alt="Image" height="50" class="mb-3">-->
          <div class="text-900 text-3xl font-medium mb-3">{{ 'login.welcome' | translate }}</div>
          <span class="text-600 font-medium">{{ 'login.signInMessage' | translate }}</span>
        </div>
        <form [formGroup]="loginForm">
          <div>
            <label for="userName" class="block text-900 text-xl font-medium mb-2">{{'login.username' | translate }}</label>
            <input id="userName" type="text" placeholder="{{'login.username' | translate }}" formControlName="userName" pInputText class="w-full md:w-30rem mb-5" style="padding:1rem">

            <label for="password" class="block text-900 font-medium text-xl mb-2">{{ 'login.password' | translate }}</label>
            <p-password id="password" formControlName="password" placeholder="{{ 'login.password' | translate }}" [feedback]="false" [toggleMask]="true" styleClass="mb-5" inputStyleClass="w-full p-3 md:w-30rem"></p-password>

            <div class="flex align-items-center justify-content-between mb-5 gap-5">
              <div class="flex align-items-center">
                <p-checkbox id="rememberme1" [binary]="true" styleClass="mr-2"></p-checkbox>
                <label for="rememberme1">{{ 'login.remember' | translate }}</label>
              </div>
<!--              <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(&#45;&#45;primary-color)">Forgot password?</a>-->
            </div>
            <button pButton pRipple [loading]="formLoading" label="{{ 'login.signIn' | translate }}" class="w-full p-3 text-xl" (click)="login()"></button>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>

