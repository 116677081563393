import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LayoutService } from '../../layout/service/app.layout.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  public formLoading: boolean = false;
  public loginForm: FormGroup = new FormGroup({
    password: new FormControl<string | null>(null, {
      validators: Validators.required
    }),
    userName: new FormControl<string | null>(null, {
      validators: Validators.required
    })
  });

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly layoutService: LayoutService
  ) {}
  ngOnInit() {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/']);
    }
  }

  login() {
    this.formLoading = true;
    this.authService.login(this.loginForm.getRawValue()).subscribe({
      next: (response: any) => {
        this.authService.storeJwtToken(response?.jwtToken);
        this.formLoading = false;
        this.router.navigate(['/']);
      },
      error: (error: any) => {
        console.log(JSON.stringify(error));
      }
    });
  }

  public layoutConfig() {
    return this.layoutService.config();
  }
}
