import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  public applicationState$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(private readonly http: HttpClient) {}

  public get apiUrl(): string {
    return environment.apiUrl;
  }

  public get apiKey(): string {
    return environment.apiKey;
  }

  public parseDataFromNode(object: any) {
    return object[environment.rootNode];
  }

  public apiHealthCheck(): boolean {
    const healthCheck = firstValueFrom(
      this.http.get(`${this.apiUrl}/api/Login`, {
        headers: {
          'FS-ApiKey': this.apiKey
        }
      })
    ).catch(error => {
      console.log(error);
    });
    return !!healthCheck;
  }
}
