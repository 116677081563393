import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';
import { Login } from './login/login.models';
import { CoreService } from '../core/core.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private coreService: CoreService,
    private router: Router
  ) {
    const stateFromStorage = localStorage.getItem('appState');
    if (stateFromStorage) {
      this.coreService.applicationState$.next(JSON.parse(stateFromStorage));
    }
    this.appState$.subscribe(value => {
      localStorage.setItem('appState', JSON.stringify(value));
    });
  }
  public get appState$(): Observable<any> {
    return this.coreService.applicationState$.asObservable();
  }

  public storeJwtToken(token: string) {
    const decodedToken = jwtDecode(token);
    this.coreService.applicationState$.next({
      ...this.appState,
      profile: { ...this.appState?.profile, token, ...decodedToken }
    });
    localStorage.setItem('appState', JSON.stringify(this.appState));
  }

  public get appState(): any {
    return this.coreService.applicationState$.getValue();
  }
  public login(request: Login): Observable<any> {
    return this.httpClient.post(`${this.coreService.apiUrl}/api/Login`, {
      email: request.userName,
      password: request.password
    });
  }

  public get isLoggedIn(): boolean {
    const storedState = localStorage.getItem('appState');
    if (!storedState) {
      return false;
    }

    return !!(
      JSON.parse(storedState)?.profile?.token || this.appState?.profile?.token
    );
  }

  public get loggedInUser() {
    return this.appState.profile;
  }

  public async getActiveUserFromApi() {
    const activeUser = await firstValueFrom(
      this.httpClient
        .get(
          `${this.coreService.apiUrl}/api/Users/${this.appState?.profile?.userID}`
        )
        .pipe(map(item => this.coreService.parseDataFromNode(item)))
    ).catch(error => {
      console.log(error);
      if (error.status === HttpStatusCode.Unauthorized) {
        localStorage.removeItem('appState');
        this.router.navigate(['login']);
      }
    });
    if (activeUser.length) {
      this.coreService.applicationState$.next({
        ...this.appState,
        profile: {
          ...this.appState?.profile,
          ...activeUser[0]
        }
      });
    }
  }

  public getAvailableRoles() {
    return this.httpClient
      .get(`${this.coreService.apiUrl}/api/Roles/0`)
      .pipe(map(item => this.coreService.parseDataFromNode(item)));
  }

  public applicationState$(): BehaviorSubject<any> {
    return this.coreService.applicationState$;
  }
}
