import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { UserPasswordChange, UserProfile } from './profile.types';
import { CoreService } from '../../core/core.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(
    private coreService: CoreService,
    private readonly httpClient: HttpClient
  ) {}
  updateUser(user: Partial<UserProfile>) {
    return this.httpClient
      .put(
        `${this.coreService.apiUrl}/api/Users/${this.appState.profile.userID}`,
        user
      )
      .pipe(
        map(() =>
          this.coreService.applicationState$.next({
            ...this.appState,
            profile: {
              ...this.appState?.profile,
              ...user
            }
          })
        )
      );
  }
  public changePassword(id: string, changePasswordRequest: UserPasswordChange) {
    return this.httpClient.post(
      `${this.coreService.apiUrl}/api/Login/changePassword/${id}`,
      changePasswordRequest
    );
  }
  private get appState(): any {
    return this.coreService.applicationState$.getValue();
  }
}
