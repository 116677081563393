import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CoreService } from './core.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(
    private httpClient: HttpClient,
    private readonly coreService: CoreService
  ) {}

  public uploadFiles(
    id: number,
    component: string,
    files: File[],
    fileName?: string
  ) {
    const formData: FormData = new FormData();
    files.forEach((file: File) => {
      formData.append('', file, file.name);
    });
    let url: string = `${this.coreService.apiUrl}/api/S3UploadFile/${id}?component=${component}`;
    if (fileName) {
      url = `${url}&fileName=${fileName}`;
    }
    return this.httpClient
      .post(url, formData)
      .pipe(map((item: Object) => this.coreService.parseDataFromNode(item)));
  }
  public downloadFile(id: number, component: string, fileName: string) {
    return this.httpClient
      .get(
        `${this.coreService.apiUrl}/api/S3DownloadFile/${id}?component=${component}&fileName=${fileName}`
      )
      .pipe(map((item: Object) => this.coreService.parseDataFromNode(item)));
  }
}
