import { HttpEvent } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { ProfileService } from './profile.service';
import { UserProfile } from './profile.types';
import { UploadService } from '../../core/upload.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
  public userRoles: SelectItem[] = [
    {
      label: 'System Administrator',
      value: 1
    },
    {
      label: 'Administrator',
      value: 2
    },
    {
      label: 'Manager',
      value: 3
    },
    {
      label: 'Referee',
      value: 4
    }
  ];
  public formLoading: boolean = false;
  public changePasswordLoading: boolean = false;

  public changePasswordForm: FormGroup = new FormGroup({
    currentPassword: new FormControl(null, { validators: Validators.required }),
    newPassword: new FormControl(null, { validators: Validators.required })
  });

  public userForm: FormGroup = new FormGroup<any>({
    role: new FormControl<any>(
      { value: null, disabled: true },
      { validators: Validators.required }
    ),
    email: new FormControl<any>(null, { validators: Validators.required }),
    firstName: new FormControl<any>(null, { validators: Validators.required }),
    lastName: new FormControl<any>(null, { validators: Validators.required })
  });
  constructor(
    private readonly authService: AuthService,
    private readonly profileService: ProfileService,
    private readonly messageService: MessageService,
    private readonly uploadService: UploadService
  ) {}

  ngOnInit() {
    if (this.authService.loggedInUser.s3Data) {
      this.uploadService
        .downloadFile(
          this.authService.loggedInUser.id,
          'users',
          this.authService.loggedInUser.s3Data
        )
        .subscribe(result => {
          this.authService.applicationState$().next({
            ...this.authService.appState,
            profile: {
              ...this.authService.appState.profile,
              profileImg: `data:${result[0].fileType};base64, ${result[0].fileData}`
            }
          });
        });
    }
    this.userForm.patchValue({
      role: this.userRoles.find(
        item => item.value === this.authService.loggedInUser?.role
      )?.value,
      email: this.authService.loggedInUser?.email,
      firstName: this.authService.loggedInUser?.firstName,
      lastName: this.authService.loggedInUser?.lastName
    });
  }

  updateUserProfile(): void {
    this.formLoading = true;
    this.profileService
      .updateUser({
        // ...this.authService.loggedInUser,
        ...this.userForm.getRawValue()
      } as UserProfile)
      .subscribe(() => (this.formLoading = false));
  }

  public changePassword() {
    this.changePasswordLoading = true;
    this.profileService
      .changePassword(
        this.authService.loggedInUser.id,
        this.changePasswordForm.getRawValue()
      )
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'User Updated',
          life: 3000
        });
        this.changePasswordLoading = false;
      });
  }

  public onProfileImgUpload(event: any) {
    this.uploadService
      .uploadFiles(
        this.authService.loggedInUser.id,
        'users',
        event.files,
        this.authService.loggedInUser.s3Data
      )
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Profile picture updated',
          life: 3000
        });
      });
  }

  public get profilePicture() {
    return this.authService.loggedInUser.profileImg;
  }
}
