<p-toast></p-toast>
<div class="grid p-fluid">
  <div class="col-12 lg:col-6">
    <div class="card">
      <h5>{{ 'profile.title' | translate }}</h5>
      <div class="text-center">
        <p-avatar *ngIf="profilePicture" [image]="profilePicture" size="xlarge" shape="circle" class="mb-3"></p-avatar>
      </div>
        <div class="grid formgrid" [formGroup]="userForm">
          <div class="col-12 m-2">
            <div class="field">
              <label for="profilePicture" class="block font-medium mb-2">{{ 'profile.profilePicture' | translate }}</label>
              <p-fileUpload id="profilePicture" name="profile" [customUpload]="true" [multiple]="false" [auto]="true" accept="image/*" mode="basic"
                            chooseLabel="Choose" (uploadHandler)="onProfileImgUpload($event)">
              </p-fileUpload>
            </div>
          </div>
          <div class="col-12 m-2">
            <label for="role" class="block font-medium mb-2">{{ 'profile.selectRole' | translate }}</label>
            <p-dropdown [options]="userRoles" formControlName="role" class="text-900" id="role"
                        placeholder="{{ 'profile.selectRole' | translate }}"
                        [showClear]="true"></p-dropdown>
          </div>
          <div class="col-12 m-2">
            <label for="email" class="block font-medium mb-2">{{ 'profile.email' | translate }}</label>
            <p-inputGroup>
              <p-inputGroupAddon>
                <i class="pi pi-envelope"></i>
              </p-inputGroupAddon>
              <input pInputText formControlName="email" id="email" type="text" class="text-900"
                     placeholder="{{ 'profile.email' | translate }}" />
            </p-inputGroup>
          </div>
          <div class="col-12 m-2">
            <label for="firstName" class="block font-medium mb-2">{{ 'profile.firstName' | translate }}</label>
            <p-inputGroup>
              <p-inputGroupAddon>
                <i class="pi pi-user-edit"></i>
              </p-inputGroupAddon>
              <input pInputText formControlName="firstName" id="firstName" type="text" class="text-900"
                     placeholder="{{ 'profile.firstName' | translate }}" />
            </p-inputGroup>
          </div>
          <div class="col-12 m-2">
            <label for="lastName" class="block font-medium mb-2">{{ 'profile.lastName' | translate }}</label>
            <p-inputGroup>
              <p-inputGroupAddon>
                <i class="pi pi-user-edit"></i>
              </p-inputGroupAddon>
              <input pInputText formControlName="lastName" id="lastName" type="text" class="text-900"
                     placeholder="{{ 'profile.lastName' | translate }}" />
            </p-inputGroup>
          </div>
          <div class="col-12 m-2">
            <button pButton pRipple label="{{ 'profile.submit' | translate }}" [loading]="formLoading"
                    class="w-full p-3" (click)="updateUserProfile()"></button>
          </div>
        </div>
    </div>
  </div>
  <div class="col-12 lg:col-6">
    <div class="card">
      <h5>{{ 'profile.changePassword' | translate }}</h5>
      <div class="grid formgrid" [formGroup]="changePasswordForm">
        <div class="col-12 lg:col-12 m-2">
          <p-password id="currentPassword" formControlName="currentPassword"
                      placeholder="{{ 'profile.currentPassword' | translate }}"
                      [feedback]="false" [toggleMask]="true" styleClass="mb-5"></p-password>
        </div>
        <div class="col-12 lg:col-12 m-2">
          <p-password id="password" formControlName="newPassword" placeholder="{{ 'profile.newPassword' | translate }}"
                      [feedback]="true"
                      [toggleMask]="true" styleClass="mb-5"></p-password>
        </div>
        <button pButton pRipple label="{{ 'profile.changePasswordSubmit' | translate }}"
                [loading]="changePasswordLoading" class="w-full p-3" (click)="changePassword()"></button>
      </div>
    </div>
  </div>
</div>
